<template>
  <tr>
    <td>
      <v-btn icon @click="rem">
        <v-icon>remove</v-icon>
      </v-btn>
    </td>
<!--    <td v-if="!areAllItemsValid && (item.valid || !item.isDuplicate)"></td>
    <td v-if="!areAllItemsValid && (item.valid || !item.isDuplicate)"></td>-->
    <td v-if="item.valid === false">
      <v-icon style="color: red">error</v-icon>
    </td>
      <v-text-field
      readonly
      :rules="[duplicateCardNoRule, parentCardRule]"
      style="display: none"
      >

      </v-text-field>
    <td v-if="item.valid === false">
        <span>{{errorMessage}}</span>
    </td>
    <td v-if="this.item.valid && !this.areAllItemsValid"></td>
    <td v-if="this.item.valid && !this.areAllItemsValid"></td>
<!--    <td v-if="item.id"><span style="color: orange">Запись существует</span></td>-->
<!--    <td v-if="item.isDuplicate && !item.id">
      <span style="color:orange"> Повторяющиеся номера карт</span>
    </td>--><!--    <td v-if="item.isDuplicate && !item.id">
      <span style="color:orange"> Повторяющиеся номера карт</span>
    </td>-->
    <td>{{ index + 1 }}</td>
    <td>
      <v-text-field
        v-model="item.cardNo"
        :rules="requiredRules"
        required
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="item.person.lastName"
        :rules="[requiredRules]"
        required
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="item.person.firstName"
        :rules="[requiredRules]"
        required
      ></v-text-field>
    </td>
    <td>
      <v-text-field v-model="item.person.middleName"></v-text-field>
    </td>
    <td>
      <v-text-field v-model="item.parentCardNo" readonly></v-text-field>
    </td>
    <td>
      <v-autocomplete
        v-model="item.relationTypeKey"
        :items="ctx.src.dict['INS_FAMILY_RELATIONS'].items"
        item-text="value"
        item-value="key"
      >
      </v-autocomplete>
    </td>
    <td>
      <v-text-field v-model="item.person.birthDate" type="date"></v-text-field>
    </td>
    <td>
      <v-autocomplete
        v-model="item.person.sexKey"
        :items="ctx.src.dict['INS_SEX'].items"
        item-text="value"
        item-value="key"
      >
      </v-autocomplete>
    </td>
    <td>
      <v-text-field v-model="item.individualContractNo"></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="identificationData"
        :rules="[requiredRules, validIinRule]"
        required
      ></v-text-field>
    </td>
    <td>
      <v-autocomplete
        v-model="item.person.cityKey"
        :items="ctx.src.dict['INS_CITY'].items"
        item-text="value"
        item-value="key"
        :rules="[requiredRules]"
        required
      >
      </v-autocomplete>
    </td>
    <td>
      <v-text-field v-model="item.person.address"></v-text-field>
    </td>
    <td>
      <v-text-field v-model="item.person.phoneMobile1" :rules="[validPhoneNumberRule]"></v-text-field>
    </td>
    <td>
      <v-text-field v-model="item.startDate" type="date"></v-text-field>
    </td>
    <td>
      <v-text-field v-model="item.endDate" type="date"></v-text-field>
    </td>
    <td>
      <v-text-field v-model="item.programName" readonly></v-text-field>
    </td>
    <!-- <td>
      <tbody>
        <tr vfor="(itm, i) in item.limitsView" :key="i">
          <td style="min-width: 15vw">
            <vtextfield
              vmodel="itm.label"
              label="Категория"
              readonly
            ></vtextfield>
          </td>
        </tr>
      </tbody>
    </td> -->
    <tbody>
      <tr v-for="categoryName in categoriesNames" :key="categoryName.id">
        <td>
          <v-text-field v-model="categoryName.name" readonly></v-text-field>
        </td>
      </tr>
    </tbody>
    <!-- <td>
      <tbody>
        <tr v-for="(itm, i) in item.limitsView" :key="i">
          <td>
            <v-text-field v-model="itm.amount" readonly></v-text-field>
          </td>
        </tr>
      </tbody>
    </td> -->
    <td>
      <v-text-field
        v-model="item.limitAmount"
        v-bind:class="{ 'warning-bottom': item.programPricesHaveBeenModified }"
      ></v-text-field>
      <span v-if="item.unionDoesNotExist" style="color: #ffae1a"
        >Объединение не существует</span
      >
      <span v-else-if="item.unionMarkedAsNonExistent" style="color: #ffae1a">
        Категории составляют Объединение
      </span>
      <span
        v-else-if="
          item.programPricesHaveBeenModified && !item.unionDoesNotExist
        "
        style="color: #ffae1a"
      >
        Изменение цены
      </span>
    </td>
    <td v-if="!item.parentCardNo || item.premium">
      <v-text-field
        v-model="item.premium"
        type="number"
        :rules="[requiredRules]"
        required
      ></v-text-field>
    </td>
    <td v-if="!item.parentCardNo">
      <v-text-field
        v-model="item.amount"
        type="number"
        :rules="[requiredRules, aboveZeroRule]"
        required
      ></v-text-field>
    </td>
  </tr>
</template>

<script>
import ctx from "@/ctx";

export default {
  props: {
    item: Object,
    index: Number,
    areAllItemsValid: Boolean,
  },
  data: () => ({
    ctx,
    requiredRules: v => (!!v || v === 0) || "Обязательное поле",
    aboveZeroRule: v => v > 0 || "Значение должно быть больше нуля",
    errorCases: []

  }),
  watch: {
    "item.serviceId": function(val) {
      this.serviceIdChanged(val);
    }
  },
  methods: {


    duplicateCardNoRule() {
      return !this.item.isDuplicate;
    },
    parentCardRule() {
      return !this.item.parentDoesNotExist;
    }
    ,
    validPhoneNumberRule() {
      return !this.item.phoneIsInvalid;
    },
    validIinRule: function() {
      return !this.item.iinIsInvalid;
    },
    serviceIdChanged(val) {
      if (!val) return (this.item.categoryId = undefined);
      let service = ctx.src.services.get(val);
      if (service) this.item.categoryId = service.categoryId;
    },
    rem() {
      this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.items.splice(
        this.index,
        1
      );
    },
    isValid() {

    }
  },
  computed: {
    identificationData: function() {
      return this.item.person.iin || this.item.person.passportNo;
    },
    categoriesNames: function() {
      let categoriesArray = new Array();
      if (!this.item.isUnion) {
        categoriesArray.push({
          id: 1,
          name: this.item.categoryName.join(", ")
        });
        return categoriesArray;
      }
      for (let i = 0; i < this.item.categoryName.length; i++) {
        categoriesArray.push({ id: i + 1, name: this.item.categoryName[i] });
      }
      return categoriesArray;
    },
    errorMessage: function() {
      if(this.item.isDuplicate)  return "Повторяющиеся номера карт"
      else if(this.item.parentDoesNotExist) return "Не найден основной застрахованный"
      else if(this.item.iinIsInvalid) return "Некорректный ИИН"
      else if (this.item.phoneIsInvalid) return "Некорректный номер телефона";
      return "";
    }
  },

  mounted() {

  }
};
</script>

<style lang="scss" scoped>
td {
  padding: 0 2px !important;
}

.warning-bottom {
  border-bottom-color: #ffae1a;
}
</style>
