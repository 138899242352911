var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-wrapper this-wrapper"},[_c('div',{staticClass:"view"},[_c('v-toolbar',{staticClass:"my-bar"},[(_vm.rightFilter('w'))?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.add}},[_c('v-icon',[_vm._v("add")])],1):_vm._e(),(_vm.rightFilter('d'))?_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.item || !_vm.item.id}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("remove")])],1)]}}],null,false,3756878059),model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Вы точно хотите удалить визит?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Отмена")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.rem}},[_vm._v("OK")])],1)],1)],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"single-select":true,"item-key":"id","show-select":"","hide-default-footer":"","loading":_vm.loading,"loading-text":"Загрузка... Пожалуйста подождите","fixed-header":""},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.visitDate4List",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateLocalFilter")(item.visitDate4List)))])]}},{key:"item.actionDate4List",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateLocalFilter")(item.actionDate4List)))])]}},{key:"item.insured.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateLocalFilter")(item.insured.startDate)))])]}},{key:"item.insured.endDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateLocalFilter")(item.insured.endDate)))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),(_vm.item)?_c('div',{staticClass:"view"},[_c('v-toolbar',{staticClass:"my-bar"},[_c('SaveButton',{attrs:{"handler":_vm.save}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('Detail',{attrs:{"vm":this,"byInsured":true}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }