<template>
  <v-dialog v-model="showDialog" max-width="40vw" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">ВНИМАНИЕ!!</span>
      </v-card-title>
      <v-card-text>
        <v-alert

          color="red"
          type="info"
          style="margin-top: 2vw"

        >
          Есть услуги которые не входят в период страхования!
          Необходимо уведомить колл центр и регистратуру
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="!confirmButtonActive" text @click="confirm">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    hasFutureServices: {
      default: false,
      type: Boolean
    },
  },
  data() {
    return {
      confirmButtonActive: false,
      showDialog: false
    }
  },

  name: "FutureServicesDialog",
  methods: {
    show() {
      this.showDialog = true;
    },
    confirm() {
      this.showDialog = false
      this.toggleConfirmButton()
    },
    toggleConfirmButton()
    {

      this.confirmButtonActive = !this.confirmButtonActive
    },

  },

  watch: {
    hasFutureServices(val) {

      this.showDialog = val

      if(val) {
        setTimeout(this.toggleConfirmButton, 5000)
      }
    }
  },
}
</script>

<style scoped>

</style>
