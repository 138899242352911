<template>
  <div class="view-wrapper this-wrapper">
    <div class="view">
      <v-toolbar class="my-bar">
        <v-btn v-if="rightFilter('w')" icon @click="add">
          <v-icon>add</v-icon>
        </v-btn>
        <v-dialog v-if="rightFilter('d')" v-model="dialogDelete" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :disabled="!item || !item.id" v-bind="attrs" v-on="on">
              <v-icon>remove</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline"
              >Вы точно хотите удалить визит?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogDelete = false">Отмена</v-btn>
              <v-btn color="primary" text @click="rem">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :single-select="true"
        item-key="id"
        show-select
        hide-default-footer
        :loading="loading"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
        @click:row="rowClick"
      >
        <template v-slot:item.visitDate4List="{ item }">
          <span>{{ item.visitDate4List | dateLocalFilter }}</span>
        </template>
        <template v-slot:item.actionDate4List="{ item }">
          <span>{{ item.actionDate4List | dateLocalFilter }}</span>
        </template>
        <template v-slot:item.insured.startDate="{ item }">
          <span>{{ item.insured.startDate | dateLocalFilter }}</span>
        </template>
        <template v-slot:item.insured.endDate="{ item }">
          <span>{{ item.insured.endDate | dateLocalFilter }}</span>
        </template>
      </v-data-table>
    </div>
    <div v-if="item" class="view">
      <v-toolbar class="my-bar">
<!--        <v-btn icon @click="save">
          <v-icon>save</v-icon>
        </v-btn>-->
        <SaveButton
        :handler="save"
        >

        </SaveButton>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <Detail :vm="this" :byInsured="true"></Detail>
    </div>
  </div>
</template>

<script>
import fetch from "@/utils/fetch";
import Detail from "@/components/visits/Detail";
import dateIsoFilter from "@/filters/dateIso.filter";
import dictFilter from "@/filters/dict.filter";
import js2vm from "@/utils/js2vm";
import ctx from "@/ctx";
import vm2js from "@/utils/vm2js";
import moment from "moment";
import rightFilter from "../../filters/right.filter";
import SaveButton from "@/components/ButtonFacade";

let Filter = function() {
  let self = this;

  let from = new Date();
  from.setDate(from.getDate() - 5);
  self.from = dateIsoFilter(from);
  self.to = dateIsoFilter(new Date());

  self.insuredId = undefined;
};

let Item = function(d) {
  let self = this;

  d.visitDate4List = d.visitDate;
  d.actionDate4List = d.actionDate;
  d.status = d.statusKey;
  d.organization = d.organizationId;
  d.doctor = d.doctorId;
  d.type = d.typeKey;

  d.visitDate = !d.visitDate ? new Date() : d.visitDate;
  d.visitTime = !d.visitTime ? moment().format("HH:mm") : d.visitTime;
  d.actionDate = !d.actionDate ? new Date() : d.actionDate;

  self.organization2vm = function(val) {
    if (!val) return {};
    return ctx.src.org.first(val);
  };
  self.doctor2vm = function(val) {
    if (!val) return {};
    return ctx.src.doctors.get(val);
  };
  self.visitDate2vm = function(val) {
    return dateIsoFilter(val);
  };
  self.actionDate2vm = function(val) {
    return dateIsoFilter(val);
  };
  self.status2vm = function(val) {
    return dictFilter("INS_VISIT_STATUS", val);
  };
  self.type2vm = function(val) {
    return dictFilter("INS_VISIT_TYPE", val);
  };

  js2vm(self, d);
};

export default {
  props: {
    vm: Object
  },
  data: () => ({
    rightFilter,
    ctx,
    currentPage: 0,
    totalPages: 0,
    selected: [],
    loading: false,
    headers: [
      {
        text: "",
        align: "middle",
        sortable: false
      },
      {
        text: "Статус",
        align: "start",
        sortable: true,
        value: "status"
      },
      { text: "Дата создания", sortable: false, value: "visitDate4List" },
      { text: "Время создания", sortable: false, value: "visitTime" },
      { text: "Тип", sortable: false, value: "type" },
      { text: "Поставщик", sortable: false, value: "organization.fullName" },
      { text: "Врач", sortable: false, value: "doctor.fullName" },
      { text: "Дата актива", sortable: false, value: "actionDate4List" },
      { text: "Время актива", sortable: false, value: "actionTime" },
      { text: "Контакты", sortable: false, value: "contacts" },
      { text: "Создал", sortable: false, value: "creatorName" }
    ],
    items: [],
    item: undefined,
    filter: new Filter(),
    dialog: false,
    dialogDelete: false
  }),
  watch: {
    selected: function(val) {
      if (val.length === 0) this.item = undefined;
      else this.item = val[0];
    },
    "vm.item.id": function(val) {
      if (!val) return;
      if (val) return this.fetchItems(val);
    }
  },
  methods: {
    add() {
      this.close();
      this.selected.push(new Item({ insuredId: this.vm.item.id }));
    },
    async save() {
      const res = await fetch.post(
        "/api/medcases/visits/save",
        vm2js(this.item)
      );
      if (res) {
        if (this.item.id) {
          let found = this.items.find(i => {
            return i.id === res;
          });
          if (found) Object.assign(found, this.item);
        } else {
          this.item.id = res;
          this.items.unshift(new Item(vm2js(this.item)));
        }
        this.close();
      }
    },
    async rem() {
      const res = await fetch.post(
        "/api/medcases/visits/delete?id=" + this.item.id
      );
      if (res) {
        this.close();
        this.items.splice(this.item, 1);
        this.dialogDelete = false;
      }
    },
    close() {
      this.selected = [];
    },
    rowClick(obj, row) {
      if (!row.isSelected) row.select(obj);
      else this.selected = [];
    },
    async fetchItems(insuredId) {
      this.loading = true;
      this.selected = [];
      this.items = [];
      this.dialog = false;
      let res = await fetch.get(
        "/api/medcases/visits/get-list-by-insured?insuredId=" + insuredId
      );
      if (res) {
        res.forEach(element => {
          this.items.push(new Item(element));
        });
      }
      this.loading = false;
    }
  },
  mounted() {
    this.fetchItems(this.vm.item.id);
  },
  components: {
    SaveButton,
    Detail
  }
};
</script>

<style lang="scss" scoped>
.this-wrapper {
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
