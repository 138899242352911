<template>
  <div class="view-wrapper this-wrapper">
    <div class="view">
      <v-toolbar class="my-bar">
        <v-btn v-if="rightFilter('d')" icon :disabled="!item" @click="dialogDelete = true">
          <v-icon>remove</v-icon>
        </v-btn>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Вы точно хотите удалить бланк?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogDelete = false">Отмена</v-btn>
              <v-btn color="primary" text @click="deleteConfirm">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer />
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :single-select="true"
        item-key="id"
        show-select
        hide-default-footer
        :loading="loading"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
        @click:row="rowClick"
      >
        <template v-slot:item.stateKey="{ item }">
          <v-icon v-if="item.service.stateKey === 'E'" color="green">done</v-icon>
          <v-icon v-else-if="item.service.stateKey === 'R'" color="blue"
            >arrow_right</v-icon
          >
          <v-icon v-else color="orange">arrow_left</v-icon>
        </template>
      </v-data-table>
    </div>
    <BlanksDetail v-if="item" :vm="this"></BlanksDetail>
    <CheckLimit :vm="this"></CheckLimit>
  </div>
</template>

<script>
import fetch from "@/utils/fetch";
import vm2js from "@/utils/vm2js";
import js2vm from "@/utils/js2vm";
import ctx from "@/ctx";
import dateLocalFilter from "../../filters/dateLocal.filter";
import CheckLimit from "../balance/Check";
import BlanksDetail from "./BlanksDetail";
import dateIsoFilter from "../../filters/dateIso.filter";
import rightFilter from "../../filters/right.filter";

let Service = function(d) {
  let self = this;

  d.visitDate = !d.visitDate ? new Date() : d.visitDate;
  d.postingDate = !d.postingDate ? new Date() : d.postingDate;
  d.quantity = !d.quantity ? 1 : d.quantity;
  d.amount = !d.amount ? 0 : d.amount;
  d.discountPercent = !d.discountPercent ? 0 : d.discountPercent;

  d.organization = d.organizationId;
  d.doctor = d.doctorId;
  d.diagnosis = d.diagnosisId;
  d.visitDate4List = d.visitDate;
  d.postingDate4List = d.postingDate;
  d.service = d.serviceId;
  d.serviceCategory = d.serviceCategoryId;

  self.organization2vm = function(val) {
    if (!val) return {};
    return ctx.src.org.first(val);
  };
  self.doctor2vm = function(val) {
    if (!val) return {};
    return ctx.src.persons.get(val);
  };

  self.diagnosis2vm = function(val) {
    if (!val) return {};
    return ctx.src.diagnosis.get(val);
  };

  self.visitDate4List2vm = function(val) {
    return dateLocalFilter(val);
  };
  self.postingDate4List2vm = function(val) {
    return dateLocalFilter(val);
  };
  self.visitDate2vm = function(val) {
    return dateIsoFilter(val);
  };
  self.postingDate2vm = function(val) {
    return dateIsoFilter(val);
  };

  self.service2vm = function(val) {
    if (!val) return {};
    return ctx.src.services.get(val);
  };
  self.serviceCategory2vm = function(val) {
    if (!val) return {};
    return ctx.src.categories.get(val);
  };

  js2vm(self, d);
};

let Item = function(d) {
  let self = this;

  self.number2vm = function(val) {
    if (!val) return "00000000";
    let num = "";
    if (val.toString().length < 8)
      for (let i = 0; i < 8 - val.toString().length; i++) {
        num = num.concat("0");
      }
    num = num.concat(val.toString());
    return num;
  };

  self.date2vm = function(val) {
    return dateLocalFilter(val);
  };

  self.service2vm = function(val) {
    return new Service(val);
  };

  js2vm(self, d);
};

export default {
  props: {
    vm: Object,
    byInvoice: { type: Boolean, default: true }
  },
  data: () => ({
    ctx,
    rightFilter,
    currentPage: 0,
    totalPages: 0,
    loading: false,
    selected: [],
    headers: [
      { text: "", value: "stateKey" },
      {
        text: "Номер",
        sortable: true,
        value: "number"
      },
      { text: "Дата создания", sortable: false, value: "date" },
      { text: "Создал", sortable: false, value: "userName" },
    ],
    items: [],
    item: undefined,
    dialog: false,
    dialogDelete: false,
    addItem: undefined,
    searchText: undefined
  }),
  watch: {
    "vm.item": function(val) {
      this.selected = [];
      this.items = [];
      if (val) return this.fetchItems();
    },
    selected: function(val) {
      if (val.length === 0) this.item = undefined;
      else {
        this.item = new Item(vm2js(val[0]));
      }
    }
  },
  methods: {
    async deleteConfirm() {
      const res = await fetch.post(
        "/api/accounting/referral-blank/delete?id=" + this.item.id
      );
      if (res) {
        const index = this.items.indexOf(this.selected[0]);
        this.items.splice(index, 1);
        this.selected = [];
        this.dialogDelete = false;
      }
    },
    async fetchItems() {
      this.items = [];
      this.loading = true;
      let res = await fetch.get(
        "/api/accounting/referral-blank/get-list?insuredId=" + this.vm.item.id
      );
      if (res) {
        res.forEach(r => {
          this.items.push(new Item(r));
        });
      }
      this.loading = false;
    },
    rowClick(obj, row) {
      if (!row.isSelected) row.select(obj);
      else this.selected = [];
    }
  },
  mounted() {
    this.vm.blanksList = this
    this.fetchItems();
  },
  components: {
    BlanksDetail,
    CheckLimit
  }
};
</script>

<style lang="scss" scoped>
.this-wrapper {
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
