<template>
  <v-btn icon color="primary" :loading="loading" @click="dialog = true">
    <v-icon>cloud_upload</v-icon>
    <v-dialog v-model="dialog" max-width="600px">
      <template>
        <v-card>
          <v-card-title>
            <span class="headline">Выберите файл...</span>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col cols="12" md="12">
                  <v-file-input
                    v-model="file"
                    accept=".xls, .xlsx, .xlsm"
                    label="Файл"
                    show-size
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="closeDialog">
              Отмена
            </v-btn>
            <v-btn :disabled="!file" color="primary" text @click="parseFile">
              Ок
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog
      v-model="dialogImport"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card
      >
        <v-toolbar class="my-bar">
          <v-toolbar-title>{{ fileName }}</v-toolbar-title>
          <v-autocomplete
            v-if="items.length > 0"
            v-model="items[0].organizationId"
            :items="ctx.src.org.activeByType('2')"
            :label="'Компания'"
            item-text="fullName"
            item-value="id"
            readonly
            dense
            filled
            rounded
            class="underwriter"
            style="margin-left: 8px"
          ></v-autocomplete>
          <v-autocomplete
            v-if="items.length > 0"
            v-model="items[0].underwriterId"
            :items="ctx.src.org.activeByType('1')"
            :label="'Страховая'"
            item-text="fullName"
            item-value="id"
            readonly
            dense
            filled
            rounded
            class="underwriter"
          ></v-autocomplete>
<!--          <v-btn icon :disabled="!valid || !validContract || clicked" :loading="tableLoading" @click="save">
            <v-icon>save</v-icon>
          </v-btn>-->
          <SaveButton
          :additional-rules="!valid || !validContract || clicked" :loading="tableLoading"
          :handler="save"
          >

          </SaveButton>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="my-content">
          <v-form ref="form" v-model="valid" style="height: 100%">
            <v-simple-table fixed-header dense style="height: 100%">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th v-if="!valid"></th>
                    <th v-if="!valid">Ошибка</th>
                    <th>№</th>
                    <th class="text-left" style="min-width: 7vw">
                      Номер карточки
                    </th>
                    <th class="text-left" style="min-width: 7vw">
                      Фамилия
                    </th>
                    <th class="text-left" style="min-width: 7vw">
                      Имя
                    </th>
                    <th class="text-left" style="min-width: 7vw">
                      Отчество
                    </th>
                    <th class="text-left" style="min-width: 7vw">
                      Прикреплен к
                    </th>
                    <th class="text-left" style="min-width: 5vw">
                      Степень родства
                    </th>
                    <th class="text-left">
                      Дата рождения
                    </th>
                    <th class="text-left" style="min-width: 5vw">
                      Пол
                    </th>
                    <th class="text-left" style="min-width: 5vw">
                      Индивидуальный № договора
                    </th>
                    <th class="text-left" style="min-width: 8vw">
                      ИИН/Номер паспорта
                    </th>
                    <th class="text-left" style="min-width: 5vw">
                      Город
                    </th>
                    <th class="text-left" style="min-width: 7vw">
                      Адрес
                    </th>
                    <th class="text-left" style="min-width: 7vw">
                      Телефон
                    </th>
                    <th class="text-left">
                      Дата начала
                    </th>
                    <th class="text-left">
                      Дата окончания
                    </th>
                    <th class="text-left" style="min-width: 10vw">
                      Программа
                    </th>
                    <th class="text-left" style="min-width: 15vw">
                      Категория
                    </th>

                    <th class="text-left" style="min-width: 5vw">
                      Лимит
                    </th>

                    <th class="text-left" style="min-width: 5vw">
                      Страховая премия
                    </th>
                    <th class="text-left" style="min-width: 5vw">
                      Страховая сумма
                    </th>
                  </tr>
                </thead>
                <tbody v-for="(item, i) in items" :key="i">
                  <InsuredImportItem
                    :item="item"
                    :index="i"
                    :areAllItemsValid="valid"
                  ></InsuredImportItem>
                </tbody>
              </template>
            </v-simple-table>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogException" width="400">
      <v-card>
        <v-card-title>
          <span style="color: red">Ошибка</span>
        </v-card-title>
        <v-card-text>
          <span>Выбран файл неверного формата</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="closeDialog">
            Ок
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
import ctx from "@/ctx";
import vm2js from "../../utils/vm2js";
import fetch from "../../utils/fetch";
import InsuredImportItem from "./InsuredImportItem";
import dateIsoFilter from "../../filters/dateIso.filter";
import js2vm from "../../utils/js2vm";
import SaveButton from "@/components/ButtonFacade";

let Item = function(d) {
  let self = this;

  self.valid = true;
  self.limitsView = [];

  self.startDate2vm = function(val) {
    return dateIsoFilter(val);
  };
  self.endDate2vm = function(val) {
    return dateIsoFilter(val);
  };

  self.categoryName = d.importCategoriesNames;
  self.limitAmount = d.limitAmount;
  self.isUnion = d.isUnion;
  js2vm(self, d);

  self.person.birthDate = dateIsoFilter(self.person.birthDate);
  // if (self.limits) {
  //   if (self.limits[0].unionId) {
  //     self.limits[0].label =
  //       ctx.src.categories.get(self.limits[0].serviceCategoryId).name +
  //       ", " +
  //       ctx.src.categories.get(self.limits[1].serviceCategoryId).name;
  //     self.limitsView.push(self.limits[0]);
  //   } else {
  //     self.limits.forEach(l => {
  //       l.label = ctx.src.categories.get(l.serviceCategoryId).name;
  //       self.limitsView.push(l);
  //     });
  //   }
  // }
};

export default {
  props: {
    vm: Object
  },
  data: () => ({
    ctx,
    items: [],
    valid: undefined,
    file: undefined,
    dialog: false,
    dialogImport: false,
    dialogException: false,
    fileName: undefined,
    loading: false,
    clicked: false,
    tableLoading: false
  }),
  methods: {
    validate(val) {
      if (this.$refs.form) this.$refs.form.validate();
      if (!val)
        this.items.sort(function(x, y) {
          return x.valid === y.valid ? 0 : x.valid ? 1 : -1;
        });
      const table = this.$refs.form.$children[0];
      if (table) {
        table.$children.forEach(row => {
          for (let i = 0; i < row.$children.length; i++) {
            if (row.$children[i].valid === false) {
              row.item.valid = false;
              break;
            } else row.item.valid = true;
          }
        });
      }
    },
    save: async function() {
      if (!this.valid) return;
      this.clicked = true;
      this.tableLoading = true;
      const res = await fetch.post(
        "/api/insurance/insured/save-parsed",
        vm2js(this.items)
      );
      this.tableLoading = false;
      if (res) {
        await ctx.src.contracts.init();
        this.vm.initList();
        this.closeDialog();
      } else this.clicked = false;
    },
    closeDialog() {
      this.dialog = false;
      this.dialogImport = false;
      this.dialogException = false;
      this.file = [];
      this.fileName = undefined;
      this.items = [];
      this.clicked = false;
    },
    async parseFile() {
      this.loading = true;
      this.dialog = false;
      this.items = [];
      let res = await fetch.upload(
        "/api/insurance/insured/parse-file",
        this.file
      );
      if (res) {
        if (res.length !== 0) {
          this.dialogImport = true;
          res.forEach(element => {
            this.items.push(new Item(element));
          });
        }
      }


      this.loading = false;
      this.fileName = this.file.name;
      this.file = undefined;

    }
  },
  mounted() {
    this.items = [];
  },
  watch: {
    valid: function(val) {
      this.validate(val);
    }
  },
  computed: {
    validContract() {
      if (this.items.length === 0) return false;
      if (!this.items[0].organizationId || !this.items[0].underwriterId)
        return false;
      return true;
    }
  },
  components: {
    SaveButton,
    InsuredImportItem
  }
};
</script>
