<template>
  <Detail :vm="this" :byInsured="true" style="top: 0;"></Detail>
</template>

<script>
import Detail from "@/components/programs/Detail";
import fetch from "@/utils/fetch";

export default {
  props: {
    vm: Object
  },
  data: () => ({
    unions: { items: [] },
    selected: undefined
  }),
  watch: {
    "vm.item.id": function(val) {
      this.selected = undefined;
      this.unions.items = [];
      if (!val) return;
      if (val) return this.fetchItems(val);
    }
  },
  methods: {
    refresh() {
      if (this.vm.balance)
        this.vm.balance.fetchItems();
    },
    async fetchItems(insuredId) {
      this.items = [];
      let res = await fetch.get(
        "/api/insurance/programs/get-unions-list-by-insured?insuredId=" +
          insuredId
      );
      if (res) {
        this.unions.items = res;
        this.selected = this.vm.item.id;
      }
    }
  },
  mounted() {
    this.fetchItems(this.vm.item.id);
  },
  components: {
    Detail
  }
};
</script>
